import React from "react";
import { Alert } from "reactstrap";

const AlertNotification = ({ title, info }) => {
  return (
    <Alert
      color="warning"
      style={{
        color: "#664d03",
        backgroundColor: "#fff3cd",
        borderColor: "#ffecb5",
        padding: "10px 15px",
        width: "70%",
        margin: "0 auto",
        position: "relative",
        zIndex: "9999",
      }}
    >
      <h6 className="alert-heading">{title}</h6>
      <hr className="m-0" />
      {info && <p className="mb-0">{info}</p>}
    </Alert>
  );
};

export default AlertNotification;
