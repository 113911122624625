import React from "react";
import { reduxForm, Field } from "redux-form";
import PropTypes from "prop-types";
import {
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormFeedback,
  Input,
  Button,
} from "reactstrap";

const renderField = ({
  input,
  icon,
  type,
  placeholder,
  className,
  meta: { touched, error },
}) => {
  return (
    <FormGroup className={className}>
      <InputGroup className="input-group-alternative">
        <InputGroupAddon addonType="prepend">
          <InputGroupText>
            <i className={icon}></i>
          </InputGroupText>
        </InputGroupAddon>
        <Input
          invalid={touched && error ? true : false}
          type={type}
          {...input}
          placeholder={placeholder}
        />
        <FormFeedback>{error}</FormFeedback>
      </InputGroup>
    </FormGroup>
  );
};
const Form = ({ submitting, handleSubmit, onSubmit }) => {
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Field
        icon="ni ni-email-83"
        placeholder="Email"
        type="email"
        name="email"
        className="mb-3"
        component={renderField}
      />
      <Field
        icon="ni ni-lock-circle-open"
        placeholder="Password"
        type="password"
        name="password"
        component={renderField}
      />
      <div className="custom-control custom-control-alternative custom-checkbox">
        <input
          className="custom-control-input"
          id=" customCheckLogin"
          type="checkbox"
        />
        <label className="custom-control-label" htmlFor=" customCheckLogin">
          <span className="text-muted">Remember me</span>
        </label>
      </div>
      <div className="text-center">
        <Button
          className="my-4"
          disabled={submitting}
          color="primary"
          type="submit"
        >
          {!submitting && `Sign in`}
          {submitting && `Loading...`}
        </Button>
      </div>
    </form>
  );
};

Form.propTypes = {
  onSubmit: PropTypes.func,
};

Form.defaultProps = {
  onSubmit: () => {},
};
const validate = (values) => {
  const errors = {};
  if (!values.email) {
    errors.email = "Required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }
  if (!values.password) {
    errors.password = "Required";
  }
  return errors;
};
export default reduxForm({
  form: "loginForm",
  validate,
})(Form);
