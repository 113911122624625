/* eslint-disable react-hooks/exhaustive-deps */
import React, { useLayoutEffect, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import map from "lodash/map";
import find from "lodash/find";
import filter from "lodash/filter";
import isEmpty from "lodash/isEmpty";
import { NavItem, NavLink, Nav, Badge } from "reactstrap";
import BlockUi from "react-block-ui";
import { AiOutlineSetting } from "react-icons/ai";
import { RiTeamFill } from "react-icons/ri";
import { IoIosListBox } from "react-icons/io";
import { GoPerson } from "react-icons/go";
import { FiGitBranch, FiGitPullRequest } from "react-icons/fi";
import { AiOutlineFundProjectionScreen } from "react-icons/ai";
import { AiOutlineProfile } from "react-icons/ai";
// import { GoRequestChanges } from "react-icons/go";
import { AiTwotoneCalendar } from "react-icons/ai";
import { TiChartPie } from "react-icons/ti";
import { AiFillMail } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import _ from "lodash";

const CustomNavItem = ({ closeCollapse }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const teamReducer = useSelector((state) => ({
    loading: false,
    activeTeam: state.team.activeTeam,
    activeUserID: state.team.activeUserID,
    members: state.team.teamMembers,
    teams: state.team.teams,
    all: state.team.all,
    timelogDayRange: state.team.timelogDayRange,
    start: state.start,
    end: state.end,
    week: state.team.week,
  }));

  const userProfile = useSelector((state) => state.auth.profile);
  const routeReducer = useSelector((state) => ({
    location: state?.router?.location,
  }));

  useLayoutEffect(() => {
    dispatch({
      type: "team/GET_USER_ACTIVE_TEAM",
      payload: {
        userId: userProfile?.id,
        companyId: !_.isEmpty(userProfile?.activeCompany)
          ? userProfile?.activeCompany?.companyId
          : "",
        role: !_.isEmpty(userProfile?.role) ? userProfile?.role : "",
        days: teamReducer.timelogDayRange,
        start: teamReducer.start,
        end: teamReducer.end,
      },
    });
  }, [userProfile]);

  useEffect(() => {
    if (teamReducer.activeTeam.activeTeamid !== undefined) {
      dispatch({
        type: "team/SET_TODAY_TIMES",
        payload: {
          selectedTeamID: teamReducer.activeTeam.activeTeamid,
          day: teamReducer.timelogDayRange,
        },
      });
      dispatch({
        type: "team/SET_TRENDS_DATA",
        payload: {
          selectedTeamID: teamReducer.activeTeam.activeTeamid,
          week: teamReducer.week,
        },
      });
      // dispatch({
      //   type: "team/SET_TOP_USERS",
      //   payload: {
      //     selectedTeamID: teamReducer.activeTeam.activeTeamid,
      //     day: teamReducer.timelogDayRange,
      //   },
      // });
    }
  }, [teamReducer.activeTeam.activeTeamid]);

  let members = [];
  if (!isEmpty(teamReducer.members)) {
    const activeMember = find(
      teamReducer.members,
      (value) => value.userUID === userProfile?.id
    );
    if (activeMember) {
      if (activeMember.userType === "Manager") {
        const sortMembers = filter(
          teamReducer.members,
          (value) => value.userUID !== userProfile?.id
        );
        const currentMember = find(
          teamReducer.members,
          (value) => value.userUID === userProfile?.id
        );
        members = [...sortMembers, currentMember];
      } else {
        members = filter(
          teamReducer.members,
          (value) => value.userUID === userProfile?.id
        );
      }
    }
  }

  let navItems = [
    {
      id: "dashboard",
      title: "Dashboard",
      type: "header",
      navLink: "",
      icon: "",
    },
    {
      id: "team-dashboard",
      title: "Team",
      type: "item",
      navLink: "/dashboard-team",
      icon: <RiTeamFill className="mr-3" size={17} />,
    },
    {
      id: "user-dashboard",
      title: "User",
      type: "item",
      navLink: "/dashboard-individual",
      icon: <GoPerson className="mr-3" size={17} />,
    },
    {
      id: "team-attendance",
      title: "Team Attendance",
      type: "item",
      navLink: "/team-attendance",
      icon: <AiTwotoneCalendar className="mr-3" size={17} />,
    },
    {
      id: "user-attendance",
      title: "User Attendance",
      type: "item",
      navLink: "/userAttendance",
      icon: <AiTwotoneCalendar className="mr-3" size={17} />,
    },
    {
      id: "manage",
      title: "Manage",
      type: "header",
      navLink: "",
      icon: "",
    },
    {
      id: "regularization",
      title: "Regularization",
      type: "item",
      navLink: "/regularization",
      icon: <FiGitBranch className="mr-3" size={17} />,
    },
    {
      id: "requests",
      title: "Regularization requests",
      type: "item",
      navLink: "/requestsregularization",
      icon: <FiGitPullRequest className="mr-3" size={17} />,
    },
    {
      id: "leave",
      title: "Leave",
      type: "item",
      navLink: "/leave",
      icon: <FiGitBranch className="mr-3" size={17} />,
    },
    {
      id: "leaverequest",
      title: "Leave requests",
      type: "item",
      navLink: "/requestsleave",
      icon: <FiGitPullRequest className="mr-3" size={17} />,
    },
    {
      id: "profile",
      title: "Profile",
      type: "item",
      navLink: "/profile",
      icon: <AiOutlineProfile className="mr-3" size={17} />,
    },
    {
      id: "manage",
      title: "Settings",
      type: "item",
      navLink: "/manage-settings",
      icon: <AiOutlineSetting className="mr-3" size={17} />,
    },
    // {
    //   id: "clients",
    //   title: "Clients",
    //   type: "item",
    //   navLink: "/manage-clients",
    //   icon: <GoPerson className="mr-3" size={17} />,
    // },
    {
      id: "projects",
      title: "Projects",
      type: "item",
      navLink: "/project",
      icon: <AiOutlineFundProjectionScreen className="mr-3" size={17} />,
    },
    {
      id: "teams",
      title: "Teams",
      type: "item",
      navLink: "/manage-teams",
      icon: <RiTeamFill className="mr-3" size={17} />,
    },
    {
      id: "tasks",
      title: "Tasks",
      type: "item",
      navLink: "/manage-tasks",
      icon: <IoIosListBox className="mr-3" size={17} />,
    },
    {
      id: "Invitations",
      title: (
        <>
          <span>Invites</span>
          {localStorage.getItem("Visited") !== false && (
            <Badge
              className="ml-2"
              style={{ color: "white", background: "red" }}
            >
              {localStorage.getItem("ItemCount")}
            </Badge>
          )}
        </>
      ),
      type: "item",
      navLink: "/inviteList",
      icon: <AiFillMail className="mr-3" size={17} />,
    },
    // {
    //   id: "analyze",
    //   title: "Analyze",
    //   type: "header",
    //   navLink: "",
    //   icon: "",
    // },
    // {
    //   id: "reports",
    //   title: "Reports",
    //   type: "item",
    //   navLink: "/analyze-reports",
    //   icon: <TiChartPie className="mr-3" size={17} />,
    // },
  ];

  function handleRouting(navItem) {
    let navUrl = navItem.navLink;
    if (navItem.id === "team-dashboard") {
      const companyId = !_.isEmpty(userProfile?.activeCompany)
        ? userProfile?.activeCompany?.companyId
        : "";
      navUrl += `?cid=${companyId}`;
    }
    history.push(navUrl);
    closeCollapse();
  }
  if (userProfile?.activeCompany?.role === "Member") {
    navItems = navItems.filter((obj) => obj.title !== "Projects");
  }
  if (
    userProfile?.activeCompany?.role === "Member" ||
    userProfile?.activeCompany?.role === "Client"
  ) {
    navItems = navItems.filter((obj) => obj.title !== "Attendance");
  }
  if (
    userProfile?.activeCompany?.role === "Member" ||
    userProfile?.activeCompany?.role === "Client"
  ) {
    navItems = navItems.filter((obj) => obj.title !== "Team");
  }

  if (userProfile?.activeCompany?.role === "Member") {
    navItems = navItems.filter((obj) => obj.id !== "requests" && obj.id !== "leaverequest" && obj.id !== "team-attendance");
  }

  return (
    <BlockUi tag="div" blocking={teamReducer.loading}>
      <Nav navbar>
        {map(navItems, (navItem) => {
          if (navItem.type === "header") {
            return (
              <>
                <hr className="my-1" />
                <NavItem id={navItem.id}>
                  <h6 className="navbar-heading ml-3 text-muted">
                    {navItem.title}
                  </h6>
                </NavItem>
              </>
            );
          } else {
            return (
              <NavItem className="cursor-pointer" id={navItem.id}>
                <NavLink
                  onClick={() => handleRouting(navItem)}
                  active={
                    navItem.navLink.includes(routeReducer.location.pathname)
                      ? true
                      : false
                  }
                >
                  {/* <a href={navItem.navLink}> */}
                  {navItem.icon}
                  <span className="sidenav-normal">{navItem.title}</span>
                  {/* </a> */}
                </NavLink>
              </NavItem>
            );
          }
        })}
      </Nav>
    </BlockUi>
  );
};

CustomNavItem.propTypes = {};

export default CustomNavItem;
