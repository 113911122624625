import XLSX from "xlsx";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { saveAs } from "file-saver";
import { Button, Col, Row, Spinner } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import DateRangePicker from "react-bootstrap-daterangepicker";
import teamDatabase from "../../services/team";

import "bootstrap-daterangepicker/daterangepicker.css";

const ExportDataModal = ({
  user,
  name,
  isAttendance = false,
  isTeamDashboard,
}) => {
  const activeUserId = user.activeUserId;
  const dispatch = useDispatch();
  const activeUser = useSelector((state) => ({
    activeUserName: state.member.activeUserName,
  }));
  const activeTeamId = useSelector((state) => ({
    activeTeamId: state.team.selectedTeamID,
    activeTeamName: state.team.active.Team,
  }));

  const attendance = useSelector((state) => ({
    loading: state.team.teamLoading,
    isMemberTeamLading: state.member.isMemberTeamLading,
    days: state.team.days?.length > 0 ? state.team.days : undefined,
    all: state.team.all,
    allMemberData: state?.member?.allMemberData,
    allMemberTimeLogData: state?.member?.allMemberTimeLogData,
    memberLoading: state?.member?.memberLoading,
  }));

  const memberTeamData = JSON.parse(localStorage.getItem("members")) || [];

  let isAttend = attendance?.days?.filter(
    (item) => item.punchData && !item.leave
  );

  let isTimelog = attendance?.days?.filter(
    (item) => item.timeLog !== undefined
  );
  const isMemberData = attendance?.allMemberData?.filter(
    (ele) => ele?.data !== null
  );

  const allProjects = useSelector((state) => ({
    projects: state.team.projects,
  }));
  const [activeTeamMembers, setActiveTeamMembers] = useState({});
  const [showButtons, setShowButtons] = useState(false);
  const [isDownload, setIsDownload] = useState({
    isAttendanceLoading: false,
    isMemberLoading: false,
  });
  const [dateRange, setDateRange] = useState({
    startDate: "",
    endDate: "",
  });

  const fetchTeamData = async () => {
    if (activeTeamId.activeTeamId) {
      const response = await teamDatabase.getTeamData(
        activeTeamId.activeTeamId
      );
      setActiveTeamMembers(response?.data?.memberDetails || {});
    }
  };
  useEffect(() => {
    fetchTeamData();
  }, [activeTeamId.activeTeamId]);

  const handlePunchObject = ({ day, punchData }) => {
    const unOrderedData = [];
    for (let key in punchData) {
      if (punchData.hasOwnProperty(key)) {
        unOrderedData.push({
          timestamp: moment(key).unix(),
          time: moment(key).format("hh:mm:ss A"),
          type: punchData[key],
        });
      }
    }

    const orderedData = unOrderedData.sort((x, y) => {
      return x.timestamp - y.timestamp;
    });
    let momentObj = moment(day, "DD-MM-YYYY");
    const newDateString = momentObj.format("MM-DD-YYYY");
    const attendanceRow = [newDateString, "Present"];
    orderedData.map(({ time: x, type }, i) => {
      if (i === 0 && type === "punchout") {
        attendanceRow.splice(2, 0, "");
      }
      attendanceRow.push(x);
    });
    return attendanceRow;
  };

  const parseJsonToExcel = (rawData, mode) => {
    let data = rawData.filter((item) => item.punchData && !item.leave);
    let row = [];
    let ExcelFileName = "";
    if (mode === "attendance") {
      let maxPunchDataLength = 0;

      for (let i = 0; i < data.length; i++) {
        const pnchDtaKeys = Object.keys(data[i].punchData);
        const currentLength = pnchDtaKeys.length;
        if (currentLength > maxPunchDataLength) {
          maxPunchDataLength = currentLength;
        }
      }

      let innerArray = ["Date", "Status"];
      for (let i = 0; i < Math.ceil(maxPunchDataLength / 2); i++) {
        innerArray.push("Punch In", "Punch Out");
      }
      if (maxPunchDataLength % 2 !== 0) {
        innerArray.pop();
      }
      row.push(innerArray);
      data.forEach((x) => {
        row.push(handlePunchObject(x));
      });

      let dataToSort = row.slice(1);

      dataToSort.sort((a, b) => (a[0] > b[0] ? 1 : -1));

      row = [row[0]].concat(dataToSort);
      ExcelFileName = `${activeUser.activeUserName || name} Attendance Data`;
    } else {
      let tempTimelog = [];
      rawData.forEach((x) => {
        const temp = handleTimeLog(x);
        tempTimelog = [...tempTimelog, ...temp];
      });

      row.push([
        "Date",
        "Project Name",
        "Task Name",
        "Task Details",
        "Task Hours",
        "Task Minutes",
        "Idle Minutes",
        "Rates($)",
        "Total Earnings($)",
        "Screen Shot",
      ]);

      tempTimelog.flatMap((x) => row.push(x));
      ExcelFileName = `${activeUser.activeUserName || name} Timelog Data`;
    }

    exportTableToExcel(row, ExcelFileName);
  };

  const exportTableToExcel = (data, filename = "Data") => {
    const sheet = XLSX.utils.aoa_to_sheet(data);

    const wbook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(wbook, sheet, filename);

    const wbout = XLSX.write(wbook, {
      bookType: "xlsx",
      bookSST: true,
      type: "binary",
    });

    function s2ab(s) {
      var buf = new ArrayBuffer(s.length);
      var view = new Uint8Array(buf);
      for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
      return buf;
    }
    saveAs(
      new Blob([s2ab(wbout)], { type: "application/octet-stream" }),
      `${filename}.xlsx`
    );
  };

  const handleTimeLog = ({ day, timeLog }) => {
    const unOrderedData = [];
    const timeLogRow = [];
    for (let key in timeLog) {
      let projectName = allProjects.projects.find(
        (project) => timeLog[key]?.projectId === project?.id
      )?.data.project_name;
      let rates = 0;
      if (activeTeamMembers && activeTeamMembers[activeUserId]) {
        rates = activeTeamMembers[activeUserId]?.rates || 0;
      }
      if (timeLog.hasOwnProperty(key)) {
        unOrderedData.push({
          timestamp: moment(key).unix(),
          time: moment(key).format("hh:mm:ss A"),
          projectName,
          taskName: timeLog[key].taskName,
          taskDetails: timeLog[key].taskDetails,
          idleMinutes: timeLog[key].idleMinutes,
          taskMinutes: timeLog[key].taskMinutes,
          taskHours: timeLog[key].taskHours,
          rates,
          totalEarnings: (
            (timeLog[key].taskHours + timeLog[key].taskMinutes / 60) *
            rates
          ).toFixed(2),
          screenShot: Object.values(timeLog[key].screenshots || {}),
        });
      }
    }

    const orderedData = unOrderedData.sort((x, y) => {
      return x.timestamp - y.timestamp;
    });
    orderedData.map(
      (
        {
          projectName,
          taskName,
          taskDetails,
          taskHours,
          taskMinutes,
          idleMinutes,
          rates,
          totalEarnings,
          screenShot,
        },
        i
      ) => {
        timeLogRow.push([
          day,
          projectName,
          taskName,
          taskDetails,
          taskHours,
          taskMinutes,
          idleMinutes,
          rates,
          totalEarnings,
          ...screenShot,
        ]);
      }
    );
    return timeLogRow;
  };

  const isWeekend = (dateString) => {
    const dateParts = dateString.split("/").map(Number); // Assuming dd/mm/yyyy format
    const date = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);
    const dayOfWeek = date.getDay();
    return dayOfWeek === 6 || dayOfWeek === 0; // Returns true if Saturday or Sunday
  };

  const convertTime = (time, timeZone) => {
    return moment.tz(time, timeZone);
  };

  const parseMemberDataJsonToExcel = () => {
    setIsDownload({ ...isDownload, isAttendanceLoading: true });
    // Collect all unique dates from the JSON data
    const allDates = Array.from(
      new Set(isMemberData.flatMap((entry) => Object.keys(entry.data)))
    );
    allDates.sort(
      (a, b) => new Date(a.replace(/:/g, "/")) - new Date(b.replace(/:/g, "/"))
    );
    const headerRow = ["Name", "Time Zone"];
    allDates.forEach((date) => {
      const formattedDate = date.replace(/:/g, "-");
      headerRow.push(formattedDate, "Punch-In", "Punch-Out");
    });
    // Prepare worksheet data for Excel
    const worksheetData = [headerRow]; // Add headers as the first row
    // Iterate through each user entry in the data
    isMemberData.forEach((entry) => {
      const rowData = [];
      const timeZone = activeTeamMembers?.[entry?.id]?.timeZone;
      const name = entry.name;
      rowData.push(name); // Add user name as the first column
      rowData.push(timeZone || "N/A");
      allDates.forEach((date, index) => {
        const dataForDate = entry.data[date] || {};
        const status =
          dataForDate.status ||
          (isWeekend(date.replace(/:/g, "/")) ? "WO" : "L");
        let punchIn = "N/A";
        let punchOut = "N/A";
        if (dataForDate.startTime) {
          const convertedPunchIn = timeZone
            ? convertTime(dataForDate.startTime, timeZone)
            : moment(dataForDate.startTime);
          punchIn = convertedPunchIn.format("HH:mm");

          if(timeZone){
            const timezoneConvertedDate = convertTime(dataForDate.startTime, timeZone).format('DD-MM-YYYY');
            const headerIndex = headerRow.findIndex(e => e == timezoneConvertedDate);
            if(headerIndex !== -1) {
              rowData[headerIndex] = status;
              rowData[headerIndex + 1] = punchIn;

              if(!dataForDate.endTime) {
                if(!rowData[headerIndex + 2]) {
                  rowData[headerIndex + 2] = 'N/A';
                }
              }
            }
          }
        }

        if (dataForDate.endTime) {
          const convertedPunchOut = timeZone
            ? convertTime(dataForDate.endTime, timeZone)
            : moment(dataForDate.endTime);
          punchOut = convertedPunchOut.format("HH:mm");

          if(timeZone){
            const timezoneConvertedDate = convertTime(dataForDate.endTime, timeZone).format('DD-MM-YYYY');
            const headerIndex = headerRow.findIndex(e => e == timezoneConvertedDate);
            if(headerIndex !== -1) {
              rowData[headerIndex + 2] = punchOut;

              if(!dataForDate.startTime) {
                if(!rowData[headerIndex]) {
                  rowData[headerIndex] = status;
                }
                if(!rowData[headerIndex + 1]) {
                  rowData[headerIndex + 1] = 'N/A';
                }
              }
            }
          }
        } 

        if(!timeZone) {
          rowData.push(status, punchIn, punchOut);
        } else {
          const timezoneConvertedDate = convertTime(moment(date, 'DD:MM:YYYY'), timeZone).format('DD-MM-YYYY');
          const headerIndex = headerRow.findIndex(e => e == timezoneConvertedDate);

          if(headerIndex !== -1) {
            if(!rowData[headerIndex]) {
              rowData[headerIndex] = status !== 'P' ? status : 'L';
            }
            if(!rowData[headerIndex + 1]){
              rowData[headerIndex + 1] = 'N/A';
            }
            if(!rowData[headerIndex + 2]){
              rowData[headerIndex + 2] = 'N/A';
            }

            if(index == allDates?.length - 1) {
              if(moment(date, 'DD:MM:YYYY') > moment(timezoneConvertedDate, 'DD-MM-YYYY')) {
                if(!rowData[headerIndex + 3]) {
                  rowData[headerIndex + 3] = isWeekend(moment(date, 'DD:MM:YYYY').add('day', 1).format('DD:MM:YYYY').replace(/:/g, "/")) ? "WO" : "L";
                }
  
                if(!rowData[headerIndex + 4]) {
                  rowData[headerIndex + 4] = 'N/A';
                }
  
                if(!rowData[headerIndex + 5]) {
                  rowData[headerIndex + 5] = 'N/A';
                }
              }
            }

            // if(index == 0) {
            //   if(moment(date, 'DD:MM:YYYY') < moment(timezoneConvertedDate, 'DD-MM-YYYY')) {
            //     if(!rowData[0]) {
            //       rowData[0] = isWeekend(moment(date, 'DD:MM:YYYY').subtract('day', 1).format('DD:MM:YYYY').replace(/:/g, "/")) ? "WO" : "L";
            //     }
  
            //     if(!rowData[1]) {
            //       rowData[1] = 'N/A';
            //     }
  
            //     if(!rowData[2]) {
            //       rowData[2] = 'N/A';
            //     }
            //   }
            // }
          }
        }
      });
      worksheetData.push(rowData); // Add user data row to worksheet
    });
    // Create a new workbook and worksheet
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet(worksheetData);
    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, "Attendance");
    // Generate Excel file and download
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const blob = new Blob([excelBuffer], {
      type: "application/octet-stream",
    });
    // Create a file name based on the team name and date range
    const teamName = activeTeamId?.activeTeamName
      ? activeTeamId.activeTeamName.toLowerCase().replace(/ /g, "_")
      : "default_team";
    const fileName = `${teamName}_attendance_${allDates[0]}_to_${
      allDates[allDates.length - 1]
    }.xlsx`;
    saveAs(blob, fileName); // Save the file
    setIsDownload({ ...isDownload, isAttendanceLoading: false }); // Reset the loading state
  };

  const handleGetData = (startDate, endDate) => {
    setDateRange({
      startDate: startDate,
      endDate: endDate,
    });
    if (isTeamDashboard) {
      const filteredMemberData = memberTeamData?.filter(
        (ele) => ele?.deleted !== true
      );
      dispatch({
        type: "member/GET_ALL_MEMBER_ATTENDANCE_DATA",
        payload: {
          teamId: user?.activeTeamId,
          activeTeamMembers: filteredMemberData,
          startDate: startDate,
          endDate: endDate,
        },
      });
      dispatch({
        type: "member/GET_ALL_MEMBER_TIME_LOG",
        payload: {
          user,
          startDate: startDate.format("DD-MM-YYYY"),
          endDate: endDate.format("DD-MM-YYYY"),
          loading: true,
        },
      });
    } else {
      dispatch({
        type: "team/GET_USER_TIMELOG_DATA",
        payload: {
          user,
          startDate: startDate.format("DD-MM-YYYY"),
          endDate: endDate.format("DD-MM-YYYY"),
        },
      });
    }
    setShowButtons(true);
  };

  const convertMinutesToHours = (totalMinutes) => {
    const duration = moment.duration(totalMinutes, "minutes");
    const hours = Math.floor(duration.asHours()); // Convert total minutes to hours
    const remainingMinutes = duration.minutes(); // Get the remaining minutes
    return `${hours}H ${remainingMinutes}M`;
  };

  const exportToExcelAllMemberTimeLog = (startDate, endDate) => {
    setIsDownload({ ...isDownload, isMemberLoading: true });
    const memberData = memberTeamData?.filter((ele) => ele?.deleted !== true);
    const workbook = XLSX.utils.book_new();
    const memberSheets = {};

    const generateDateRange = (start, end) => {
      let dateArray = [];
      let currentDate = moment(start);
      let endMoment = moment(end);

      while (currentDate <= endMoment) {
        dateArray.push(moment(currentDate).format("DD-MM-YYYY"));
        currentDate = moment(currentDate).add(1, "days");
      }

      return dateArray;
    };

    const dateRange = generateDateRange(startDate, endDate);
    const isAllMemberTimelog = attendance?.allMemberTimeLogData || [];

    memberData.forEach((teamMember) => {
      const memberId = teamMember?.userUID;
      const memberTimelog = isAllMemberTimelog?.filter(
        (log) => log?.memberId === memberId
      );
      let memberRecord = {};

      if (memberTimelog) {
        memberTimelog.forEach((member) => {
          Object.keys(member?.timelog || {}).forEach((ele) => {
            const formattedDate = moment(ele).format("DD-MM-YYYY");
            const taskLogs = member?.timelog?.[ele];

            if (!memberRecord[formattedDate]) {
              memberRecord[formattedDate] = [];
            }

            memberRecord[formattedDate].push(taskLogs);
          });
        });
      }
      const sheetData = dateRange?.flatMap((date) => {
        const logsForDate = memberRecord?.[date] || [];
        if (logsForDate?.length > 0) {
          return logsForDate?.map((logForDate) => {
            const {
              taskName,
              taskHours,
              taskDetails,
              idleMinutes,
              taskMinutes,
              projectId,
            } = logForDate;

            let projectName =
              allProjects?.projects?.find(
                (project) => projectId === project?.id
              )?.data?.project_name || "Unknown Project";

            let rates = teamMember?.rates || 0;
            const totalMinutes = taskHours * 60 + taskMinutes;
            const productiveMinutes = totalMinutes - idleMinutes;

            return {
              Date: date,
              Project: projectName,
              "Task Name": taskName || "No Task Name",
              "Task Details": taskDetails || "",
              "Total Time": `${taskHours}H ${taskMinutes}M`,
              "Total Idle Time": convertMinutesToHours(idleMinutes),
              "Productive Time": convertMinutesToHours(productiveMinutes),
              "Total Earnings": (
                (taskHours + taskMinutes / 60) *
                rates
              ).toFixed(2),
              "Rates ($)": rates,
              "Screen Shot": "",
            };
          });
        } else {
          return {
            Date: date,
            Project: "No Data Available",
            "Task Name": "No Data Available",
            "Task Details": "",
            "Total Time": "No Data Available",
            "Total Idle Time": "No Data Available",
            "Productive Time": "No Data Available",
            "Total Earnings": "No Data Available",
            "Rates ($)": "No Data Available",
            "Screen Shot": "",
          };
        }
      });

      // Assign sheet data to the member ID
      memberSheets[memberId] = sheetData;
    });

    // Track name occurrences to handle duplicates
    const nameOccurrences = {};

    // Create a worksheet for each member and apply cell merges
    Object.keys(memberSheets).forEach((memberId) => {
      const worksheet = XLSX.utils.json_to_sheet(memberSheets[memberId]);
      let memberName = memberData?.find(
        (member) => member.userUID === memberId
      )?.userName;

      // If the name is already used, append a suffix to make it unique
      if (nameOccurrences[memberName] !== undefined) {
        nameOccurrences[memberName]++;
        memberName = `${memberName} ${nameOccurrences[memberName]}`;
      } else {
        nameOccurrences[memberName] = 0;
      }

      // Truncate sheet name if it exceeds the Excel sheet name length limit (31 characters)
      const truncatedName =
        memberName.length > 31
          ? memberName.substring(0, 28) + "..."
          : memberName;

      const merges = [];

      // Loop through the sheet data and identify rows with "No Data Available"
      memberSheets[memberId].forEach((rowData, index) => {
        if (rowData.Project === "No Data Available") {
          merges.push({
            s: { r: index + 1, c: 1 },
            e: { r: index + 1, c: 9 },
          });

          for (let col = 1; col <= 9; col++) {
            const cellRef = XLSX.utils.encode_cell({ r: index + 1, c: col });
            if (!worksheet[cellRef]) worksheet[cellRef] = {};
            worksheet[cellRef].s = {
              alignment: {
                horizontal: "center",
                vertical: "center",
              },
            };
          }
        }
      });

      worksheet["!merges"] = merges;

      // Append the worksheet to the workbook with the unique name
      XLSX.utils.book_append_sheet(workbook, worksheet, truncatedName);
    });

    // Generate Excel file and download
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    const teamName = activeTeamId?.activeTeamName
      ? activeTeamId.activeTeamName.toLowerCase().replace(/ /g, "_")
      : "default_team";
    const fileName = `${teamName}_TimelogData.xlsx`;

    saveAs(blob, fileName);
    setIsDownload({ ...isDownload, isMemberLoading: true });
  };

  return (
    <div className="chart-sm">
      <Row>
        <Col md={12}>
          <DateRangePicker
            initialSettings={{
              locale: {
                applyLabel: "Get Data",
              },
            }}
            onApply={(_event, { startDate, endDate }) =>
              handleGetData(startDate, endDate)
            }
          >
            <input type="text" className="form-control form-control-sm" />
          </DateRangePicker>
        </Col>
      </Row>
      {!isTeamDashboard &&
        showButtons &&
        isTimelog &&
        isTimelog?.length > 0 && (
          <div
            style={{
              paddingTop: "20px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {isAttend && isAttend.length > 0 && (
              <Button
                size="sm"
                onClick={() => parseJsonToExcel(attendance.days, "attendance")}
                color="info"
              >
                Download Attendance Data
              </Button>
            )}
            <br />
            {!isAttendance && (
              <Button
                size="sm"
                onClick={() => parseJsonToExcel(attendance.days, "timelog")}
                color="info"
              >
                Download Timelog Data
              </Button>
            )}
          </div>
        )}
      {(attendance.loading ||
        attendance?.memberLoading ||
        attendance?.isMemberTeamLading) && (
        <div
          style={{
            display: "flex",
            padding: "10px",
            justifyContent: "center",
          }}
        >
          <Spinner color="info" />
        </div>
      )}
      {isTeamDashboard &&
        showButtons &&
        !attendance?.memberLoading &&
        !attendance?.isMemberTeamLading && (
          <div
            style={{
              paddingTop: "20px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Button
              size="sm"
              onClick={() => parseMemberDataJsonToExcel()}
              color="info"
            >
              Download Attendance Data
            </Button>
          </div>
        )}
      {isTeamDashboard &&
        showButtons &&
        !attendance?.memberLoading &&
        !attendance?.isMemberTeamLading && (
          <div
            style={{
              paddingTop: "20px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Button
              size="sm"
              onClick={() =>
                exportToExcelAllMemberTimeLog(
                  dateRange?.startDate,
                  dateRange?.endDate
                )
              }
              color="info"
            >
              Download Time Log Data
            </Button>
          </div>
        )}
    </div>
  );
};

export default ExportDataModal;
