const actions = {
  SET_STATE: "team/SET_STATE",
  SET_SELECT_DATE: "team/SET_SELECT_DATE",
  SET_SELECT_TEAM_ID: "team/SET_SELECT_TEAM_ID",
  SWITCH_COMPANY_ACTIVE_TEAM: "team/SWITCH_COMPANY_ACTIVE_TEAM",
  SET_SELECTED_MEMBER_ID: "team/SET_SELECTED_MEMBER_ID",
  SET_TRENDS_DATA: "team/SET_TRENDS_DATA",
  SET_TIMELOG_DAY_RANGE: "team/SET_TIMELOG_DAY_RANGE",
  GET_DATE_RANGE_DATA: "team/GET_DATE_RANGE_DATA",
  GET_USER_ACTIVE_TEAM: "team/GET_USER_ACTIVE_TEAM",
  GET_USER_TEAM_COLLECTION: "team/GET_USER_TEAM_COLLECTION",
  GET_MANAGER_TEAM_COLLECTION: "team/GET_MANAGER_TEAM_COLLECTION",
  // GET_SELECTED_TEAM_COLLECTION: "team/GET_SELECTED_TEAM_COLLECTION",
  GET_USER_TEAMS: "team/GET_USER_TEAMS",
  GET_USER_TIMELOG_DATA: "team/GET_USER_TIMELOG_DATA",
  GET_TEAM_ATTENDANCE_DATA: "team/GET_TEAM_ATTENDANCE_DATA",
  GET_ALL_TEAMS_DATA: "team/GET_ALL_TEAMS_DATA",
  GET_ALL_TEAM_TIMELOG: "team/GET_ALL_TEAM_TIMELOG",
  SET_THRESHOLD: 'team/SET_THRESHOLD',
  GET_THRESHOLD:'team/GET_THRESHOLD',
  GET_TIME_DATA:'team/GET_TIME_DATA',
  SET_TODAY_TIMES: "team/SET_TODAY_TIMES",
  GET_TASKS: "team/GET_TASKS",
  ADD_TASKS: "team/ADD_TASKS",
  ADD_PROJECT: "team/ADD_PROJECT",
  SET_TOP_USERS: "team/SET_TOP_USERS",
  GET_PROJECT: "team/GET_PROJECT",
  UPDATE_PROJECT: "team/UPDATE_PROJECT",
  DELETE_PROJECT: "team/DELETE_PROJECT",
  SET_TEAM_DATA: "team/SET_TEAM_DATA",
};

export default actions;
