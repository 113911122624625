import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "reactstrap";
import Applogo from '../../assets/img/brand/logo.png';

const AuthLayout = ({ children }) => {
    useEffect(() => {
        document.body.classList.add("bg-default");
        return () => {
            document.body.classList.remove("bg-default");
        }
    }, []);
    return (
        <>
            <div className="main-content">
                <div className="header bg-gradient-info py-7 py-lg-7">
                    <Container>
                        {/* <div className="header-body text-center mb-3">
                            <Row className="justify-content-center">
                                <Col lg="5" md="6">
                                    <img className="auth-app-logo" src={Applogo} alt="workfromhome" />
                                </Col>
                            </Row>
                        </div> */}
                    </Container>
                    <div className="separator separator-bottom separator-skew zindex-100">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            preserveAspectRatio="none"
                            version="1.1"
                            viewBox="0 0 2560 100"
                            x="0"
                            y="0"
                        >
                            <polygon
                                className="fill-default"
                                points="2560 0 2560 100 0 100"
                            />
                        </svg>
                    </div>
                </div>
                <Container className="mt--8 pb-5">
                    <Row className="justify-content-center">
                        {children}
                    </Row>
                </Container>
            </div>
        </>
    );
};

AuthLayout.propTypes = {
    children: PropTypes.instanceOf(Object).isRequired
};

export default AuthLayout;
    