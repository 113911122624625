import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import BlockUi from 'react-block-ui';
import MainLayout from "./Main";
import AuthLayout from "./Auth";
import { appRoutes } from './../routes/index';
import BlankLayout from './Blank/index';
import _ from 'lodash'
import { removeNotification } from "../components/Notification";

const Layouts = {
  auth: AuthLayout,
  main: MainLayout,
  blank: BlankLayout
};

class IndexLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      children,
      auth,
      location: { pathname }
    } = this.props;

    const cid = ''

    const getLayout = () => {
      if (pathname == '/add-company' || pathname == '/select-company') {
        return 'blank'
      }
      if (pathname === '/' || pathname == '/invitation') {
        return 'auth'
      }
      if (/^\/auth(?=\/|$)/i.test(pathname)) {
        return "auth"
      }
      return "main";
    };

    const publicRoutes = ['/invitation']
    const isPublicRoute = publicRoutes.includes(pathname) ? true : false
    const Container = Layouts[getLayout()]
    const isUserAuthorized = auth.authorized
    const isEmailVerified = auth?.profile?.isEmailVerified
    const userRole = auth?.profile?.role
    const isUserLoading = auth.loading
    const isLoginLayout = getLayout() === 'auth'


    const BootstrappedLayout = () => {
      if (pathname == '/add-company' || pathname == '/select-company') {
        return <Container>{children}</Container>
      }
      if (isUserLoading && !isUserAuthorized && !isLoginLayout) {
        return <div className="loader-container">
          <BlockUi tag="div" blocking message="Loading, please wait" />
        </div>
      }
      if (!isLoginLayout && !isUserAuthorized) {
        return <Redirect to="/auth/login" />
      }
      if (isLoginLayout && isUserAuthorized && !isPublicRoute) {
        removeNotification();
        return <Redirect to={'/dashboard-team' + (cid ? `?cid=${cid}` : '')} strict />
      }
      return <Container>{children}</Container>
    }

    return BootstrappedLayout();
  }
}

IndexLayout.propTypes = {
  children: PropTypes.instanceOf(Object).isRequired,
  location: PropTypes.instanceOf(Object)
};

IndexLayout.defaultProps = {
  location: {}
};

export default connect(({ auth }) => ({ auth }))(withRouter(IndexLayout));
