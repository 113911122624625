import React from 'react';
import PropTypes from 'prop-types';
import {
    Card,
    CardHeader,
    CardBody,
    Col,
    Alert
} from "reactstrap";
import LoginForm from './Form';
import AppLogo from './../../../assets/img/brand/logo.png';
import {Link} from "react-router-dom"
import { useLocation } from 'react-router';
import queryString from 'query-string';

const Login = ({ dispatch }) => {
    const handleSubmit = (values) => {
        dispatch({
            type: "user/LOGIN",
            payload: values
        })
    }

    const location = useLocation()
    const { i: invitationId } = queryString.parse(location.search)
    const invitationData = JSON.parse(localStorage.getItem('pendingInvitation'))


    return (
        <>
            <Col lg="5" md="7">
                <Card className="bg-secondary shadow border-0">
                    <CardHeader className="bg-transparent pb-2 border-0">
                        <div className="text-center">
                            <img className="auth-app-logo" src={AppLogo} alt="workfromhome" />
                        </div>
                        <div className="text-muted text-center mt-2 mb-3">
                            <h2>Welcome!</h2>
                            <small>Log into your account</small>
                        </div>

                        {(invitationId && (invitationId == invitationData?.invitationId && invitationData?.userDetails?.registered)) 
                        && <div className="mt-3">
                            <Alert color="primary">
                                <p className="mb-0 text-sm">
                                    You have been invited to join the {' '}
                                    <b className="font-weight-bold text-dark">{invitationData?.invitedBy?.fullName}'s</b> {' '}
                                    {invitationData?.invitedAs == 'Admin'
                                    ? <>company <b className="font-weight-bold text-dark">{invitationData?.invitedBy?.companyDetails?.companyName} ({invitationData?.invitedAs})</b></>
                                    : <>team <b className="font-weight-bold text-dark">{invitationData?.invitedTo?.teamName} ({invitationData?.invitedAs})</b></>}.
                                    Please Sign In, to accept the invitation.
                                </p>
                            </Alert>
                        </div>}

                    </CardHeader>
                    <CardBody className="px-lg-5 py-lg-4">
                        <LoginForm onSubmit={handleSubmit} />
                    </CardBody>
                </Card>
                <div className="pb-5 pt-2 text-center">                
                    <small className="text-white">Don't have an account? <Link to={'/auth/sign-up'}>Sign up now.</Link></small>
                </div>
            </Col>
        </>
    )
};

Login.propTypes = {
    dispatch: PropTypes.func
};

Login.defaultProps = {
    dispatch: () => { }
}

export default Login