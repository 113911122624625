import React from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody } from "reactstrap";

const CustomModal = ({
  isOpen,
  totalTime,
  selectedDate,
  title,
  children,
  onClose,
  size,
}) => {
  return (
    <Modal
      className="modal-dialog-centered"
      size={size}
      isOpen={isOpen}
      toggle={onClose}
      centered={false}
      scrollable={false}
    >
      <div className="modal-header">
        <h3 className="modal-title text-capitalize" id="modal-title-default">
          {title} {selectedDate}
        </h3>
        {title === "timelog" && (
          <div className="timeContainer">
            <time>{totalTime}</time>
          </div>
        )}
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={onClose}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <ModalBody>{children}</ModalBody>
    </Modal>
  );
};

CustomModal.propTypes = {
  title: PropTypes.string,
  totalTime: PropTypes.string,
  selectedDate: PropTypes.string,
  isOpen: PropTypes.bool,
  children: PropTypes.instanceOf(Object),
  onClose: PropTypes.func,
};

CustomModal.defaultProps = {
  title: "Modal Title",
  totalTime: "0 hours 0 minutes",
  selectedDate: "",
  size: "lg",
  isOpen: false,
  children: <div></div>,
  onClose: () => {},
};

export default CustomModal;
